import { Component } from "@angular/core";
import { AuthService } from "@core/services/auth/auth.service";
import { BusinessService } from "@data/services/business.service";

@Component({
	selector: "app-base",
	templateUrl: "./base.component.html",
	styleUrls: ["./base.component.scss"],
	standalone: false
})
export class BaseComponent {
	pageName = "";

	constructor(
		private authService: AuthService,
		private businessService: BusinessService
	) {}

	protected closeMobileSidebar(submenuName: string) {
		this.pageName = submenuName;
	}

	protected isUserSupportAndIsNotLoggedIn(): boolean {
		return (
			!!this.authService.getUserSupport() &&
			!this.authService.isSupportLoggedInAsNormalUser() &&
			this.authService.getRouterEventDone()
		);
	}

	protected canGoToHome(): boolean {
		return this.authService.canGoToHome();
	}

	protected getCompanyName(): string {
		return this.businessService.getBusinessData()?.nomeFantasia ?? "";
	}
}
