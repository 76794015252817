import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	ViewChild
} from "@angular/core";
import { Router } from "@angular/router";
import { MenuService } from "@core/services/auth/menu.service";
import { CustomSegmentService } from "@core/services/custom-segment.service";
import { AccountingAccountVersion } from "@data/schemas/accounting-account/accounting-account-version";
import { AccountingAccountService } from "@data/services/accounting-account.service";
import { SidebarSubmenuItem } from "@shared/schemas/sidebar-submenu";
import { SidebarMenuService } from "@shared/services/sidebar-menu.service";
import { Subject, takeUntil } from "rxjs";

@Component({
	selector: "app-sidebar-menu",
	templateUrl: "./sidebar-menu.component.html",
	styleUrls: ["./sidebar-menu.component.scss"],
	standalone: false
})
export class SidebarMenuComponent implements OnInit, OnDestroy {
	@Input() sidebarExpanded = true;

	@Input() permissions: string[] = [];
	@Input() menuId = "";
	@Input() menuName = "";
	@Input() menuClassIcon = "";
	@Input() showNewBadge = false;

	@Input() submenus: SidebarSubmenuItem[] = [];
	@Input() showNewsHotspot: boolean = false;

	@Output() closeMobileSidebar = new EventEmitter<string>();

	@ViewChild("submenu")
	submenu!: ElementRef<HTMLLIElement>;

	@ViewChild("menu")
	menu!: ElementRef<HTMLAnchorElement>;

	private _onDestroy = new Subject<boolean>();

	protected isClicked: boolean;
	protected hotspotNewsWasClicked: boolean;
	protected isNewAccountingAccount: boolean = true;
	protected principalMenuClicked: boolean = false;
	protected submenuClicked: boolean = false;

	constructor(
		public router: Router,
		private sidebarMenuService: SidebarMenuService,
		private renderer: Renderer2,
		private menuService: MenuService,
		private segment: CustomSegmentService,
		private accountingAccountService: AccountingAccountService
	) {
		this.isClicked = !this.sidebarMenuService.optionWasClicked;
		this.hotspotNewsWasClicked =
			!this.sidebarMenuService.hotspotNewsWasClicked;
	}

	ngOnInit(): void {
		this.showHideMenu();
		this.checkNewBadge();
		this.verifyIfIsNewUser();
	}

	private showHideMenu(): void {
		this.sidebarMenuService.actualMenu$
			.pipe(takeUntil(this._onDestroy))
			.subscribe((actualMenuId) => {
				if (
					actualMenuId !== this.menuId &&
					!!actualMenuId &&
					!this.isRouterActive()
				) {
					if (
						this.submenu?.nativeElement.classList.contains("show")
					) {
						this.renderer.removeClass(
							this.submenu?.nativeElement,
							"show"
						);
						this.renderer.setAttribute(
							this.menu.nativeElement,
							"aria-expanded",
							"false"
						);
					}
				}
			});
	}

	private checkNewBadge(): void {
		for (const submenu of this.submenus) {
			if (submenu.showNewBadge) {
				this.showNewBadge = true;
				break;
			}
		}
	}

	ngOnDestroy(): void {
		this._onDestroy.next(true);
	}

	closeMobileSidebarMenu(submenuId: string): void {
		this.closeMobileSidebar.emit(submenuId);
	}

	emitActualMenu(): void {
		this.sidebarMenuService.setActualmenu(this.menuId);
	}

	isRouterActive(): boolean {
		return this.router.isActive(this.menuId, {
			paths: "subset",
			queryParams: "subset",
			fragment: "ignored",
			matrixParams: "ignored"
		});
	}

	handleSubmenuClick(item: SidebarSubmenuItem): void {
		this.closeMobileSidebarMenu(item.menuName);
		this.trackMenuItemClick(item);
		this.submenuClicked = true;

		if (item.showNewBadge === true) {
			this.isClicked = false;
			this.sidebarMenuService.markClickedButton();
		}
	}

	trackMenuItemClick(item: SidebarSubmenuItem): void {
		if (item.menuName === "SIDEBAR.FINANCIAL.FINANCIAL_TRANSACTIONS") {
			this.segment.track({
				table: "track__financial_transactions",
				screen: "home",
				action: "base_erp_financial_transaction_menu"
			});
		}
	}

	handleHotstopNewsClick(item: SidebarSubmenuItem): void {
		if (
			item.showNewBadge === true &&
			this.isNewAccountingAccount === true
		) {
			this.hotspotNewsWasClicked = false;
			this.sidebarMenuService.markClickedButton();
		}
	}

	private verifyIfIsNewUser(): void {
		this.accountingAccountService
			.getAccountingAccountVersion()
			.pipe(takeUntil(this._onDestroy))
			.subscribe((version: AccountingAccountVersion) => {
				this.isNewAccountingAccount =
					version.newAccountingAccountsEnabled;
			});
	}

	menuClick(): void {
		this.principalMenuClicked = true;
	}
}
